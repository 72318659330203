// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-algemene-informatiepagina-js": () => import("./../../../src/pages/algemene-informatiepagina.js" /* webpackChunkName: "component---src-pages-algemene-informatiepagina-js" */),
  "component---src-pages-algemene-voorwaarden-js": () => import("./../../../src/pages/algemene-voorwaarden.js" /* webpackChunkName: "component---src-pages-algemene-voorwaarden-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-huiswerk-js": () => import("./../../../src/pages/huiswerk.js" /* webpackChunkName: "component---src-pages-huiswerk-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lesaanbod-abc-zwemles-js": () => import("./../../../src/pages/lesaanbod/abc-zwemles.js" /* webpackChunkName: "component---src-pages-lesaanbod-abc-zwemles-js" */),
  "component---src-pages-lesaanbod-mini-survival-zwemmen-js": () => import("./../../../src/pages/lesaanbod/mini-survival-zwemmen.js" /* webpackChunkName: "component---src-pages-lesaanbod-mini-survival-zwemmen-js" */),
  "component---src-pages-over-ons-js": () => import("./../../../src/pages/over-ons.js" /* webpackChunkName: "component---src-pages-over-ons-js" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-pages-privacyverklaring-js": () => import("./../../../src/pages/privacyverklaring.js" /* webpackChunkName: "component---src-pages-privacyverklaring-js" */),
  "component---src-pages-vacatures-js": () => import("./../../../src/pages/vacatures.js" /* webpackChunkName: "component---src-pages-vacatures-js" */),
  "component---src-pages-wachtlijst-js": () => import("./../../../src/pages/wachtlijst.js" /* webpackChunkName: "component---src-pages-wachtlijst-js" */),
  "component---src-templates-location-js": () => import("./../../../src/templates/location.js" /* webpackChunkName: "component---src-templates-location-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-vacature-js": () => import("./../../../src/templates/vacature.js" /* webpackChunkName: "component---src-templates-vacature-js" */),
  "component---src-templates-wachtlijst-detail-js": () => import("./../../../src/templates/wachtlijst-detail.js" /* webpackChunkName: "component---src-templates-wachtlijst-detail-js" */)
}

